import _ from "lodash";


import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";

import {ApiGameSummaryQuarterType} from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter";

import GameProgressSummaryInterface
    from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/ProgressSummary";
import GameProgressInterface from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress";
import {
    GameProgressBlockEventInterface,
    GameProgressDefaultEventInterface,
    GameProgressFoulEventInterface,
    GameProgressPointsEventInterface,
    GameProgressReboundEventInterface,
    GameProgressSubstitutionEventInterface,
    GameProgressTeamReboundEventInterface,
    GameProgressThrowEventInterface,
    GameProgressTurnoverEventInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";

import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";

import {
    GameTeamsPointsInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types/Points";


export const _createGameProgressDetails = (
    SelectedQuarters: ApiGameSummaryQuarterType[],
    GameSummaryData: ApiGameSummaryInterface | null,
    homeTeamID: number,
    awayTeamID: number,
) : GameProgressInterface | boolean =>  {

    if (!SelectedQuarters || !GameSummaryData) {
        return false;
    }

    const GameEventsSummary: GameProgressSummaryInterface = {
        result: {
            [homeTeamID]: 0, [awayTeamID]: 0
        },
        players: {},
        teams: {
            [homeTeamID]: {
                quarters: {}
            },
            [awayTeamID]: {
                quarters: {}
            }
        }
    };

    const {
        quarters: gameQuarters
    } = GameSummaryData;


    const GameProgress: GameProgressInterface = {};


    const GameQuarters = Object.entries(gameQuarters);

    let eventOrderNr = 1;

    for (let [quarterNumber, quarterData] of GameQuarters) {

        // @ts-ignore
        if (!SelectedQuarters.includes(+quarterNumber)) {
            continue;
        }

        // @ts-ignore
        GameProgress[quarterNumber] = [];

        const {
            events_list: quarterEvents
        } = quarterData;

        const QuarterEvents = _.sortBy(quarterEvents, ["league_game_event_idx"]);

        if (!quarterEvents) {
            continue;
        }

        for (let i = 0; i < QuarterEvents.length; i++) {

            const {
                league_game_event_idx: eventID,
                league_game_event_type: eventType, league_game_event_description: eventDescription,
                league_game_event_time: eventTime,
                team_id: eventTeamID, player_id: eventPlayerID, league_game_event_player_number: eventPlayerNumber
            } = QuarterEvents[i];

            if (eventType === "TIME_OUT") {
                continue;
            }

            if (["1PT", "2PT", "3PT"].includes(eventType) && eventTeamID) {

                let eventPoints = 0;

                if (eventType === "1PT") {
                    eventPoints = 1;
                } else if (eventType === "2PT") {
                    eventPoints = 2;
                } if (eventType === "3PT") {
                    eventPoints = 3;
                }

                GameEventsSummary.result[eventTeamID] += eventPoints;

            }
            let eventPlayerEventRepeatNumber = 0;
            let eventPlayerActualPoints = 0;

            if (eventPlayerID) {

                if (!GameEventsSummary.players[eventPlayerID]) {
                    GameEventsSummary.players[eventPlayerID] = {};
                }

                if (!GameEventsSummary.players[eventPlayerID][eventType]) {
                    GameEventsSummary.players[eventPlayerID][eventType] = 0;
                }

                // @ts-ignore
                GameEventsSummary.players[eventPlayerID][eventType] += 1;

                // @ts-ignore
                eventPlayerEventRepeatNumber = GameEventsSummary.players[eventPlayerID][eventType];

                if (GameEventsSummary.players[eventPlayerID]["1PT"]) {
                    // @ts-ignore
                    eventPlayerActualPoints += GameEventsSummary.players[eventPlayerID]["1PT"] || 0;
                }

                if (GameEventsSummary.players[eventPlayerID]["2PT"]) {
                    // @ts-ignore
                    eventPlayerActualPoints += GameEventsSummary.players[eventPlayerID]["2PT"] * 2 || 0;
                }

                if (GameEventsSummary.players[eventPlayerID]["3PT"]) {
                    // @ts-ignore
                    eventPlayerActualPoints += GameEventsSummary.players[eventPlayerID]["3PT"] * 3 || 0;
                }

            }

            let eventTeamIDType: "HOME" | "AWAY" | undefined = undefined;

            if (eventTeamID === homeTeamID) {
                eventTeamIDType = "HOME";
            } else if (eventTeamID === awayTeamID) {
                eventTeamIDType = "AWAY";
            }

            if (["FOUL", "FOUL_TECH", "FOUL_NS", "FOUL_DSQ", "CO_FOUL", "CO_FOUL_TECH", "CO_FOUL_DSQ"].includes(eventType)) {
                if (eventTeamID) {
                    // @ts-ignore
                    if (!GameEventsSummary.teams[eventTeamID].quarters[quarterNumber]) {
                        // @ts-ignore
                        GameEventsSummary.teams[eventTeamID].quarters[quarterNumber] = { fouls: 0};
                    }
                    // @ts-ignore
                    GameEventsSummary.teams[eventTeamID].quarters[quarterNumber].fouls += 1;
                }
            }


            if (["AST", "BLK VIC", "STL", "SUB_OUT", "FOUL", "FOUL_TECH", "FOUL_NS", "FOUL_DSQ", "CO_FOUL", "CO_FOUL_TECH", "CO_FOUL_DSQ", "WAL"].includes(eventType)) {
                continue
            }

            // @ts-ignore
            if (!GameEventsSummary.players[eventPlayerID]) {
                continue;
            }


            const PreviousEvent = QuarterEvents[i - 1];

            if (!PreviousEvent) {
                continue;
            }

            const {
                league_game_event_type: previousEventType,
                team_id: previousEventTeamID,
                player_id: previousEventPlayerID,
                league_game_event_player_number: previousEventPlayerNumber
            } = PreviousEvent;


            if (["1PT", "2PT", "3PT"].includes(eventType)) {

                let eventThrowType = 0;

                if (eventType === "1PT") {
                    eventThrowType = 1;
                } else if (eventType === "2PT") {
                    eventThrowType = 2;
                } else if (eventType === "3PT") {
                    eventThrowType = 3;
                }

                if (!eventThrowType) {
                    continue;
                }

                // @ts-ignore
                const GameTeamsPoints: GameTeamsPointsInterface = {
                    homeTeam: GameEventsSummary.result[homeTeamID],
                    awayTeam: GameEventsSummary.result[awayTeamID]
                };

                const PointsEvent: GameProgressPointsEventInterface = {
                    eventType: GameProgressEventTypes.POINTS, eventThrowType, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription,
                    eventTeamsPoints: GameTeamsPoints,
                    eventThrowingPlayerID: eventPlayerID, eventThrowingPlayerTeamID: eventTeamID,
                    eventThrowingPlayerNumber: eventPlayerNumber, eventThrowingPlayerPointsNumber: eventPlayerActualPoints,
                }

                const NextEvent = QuarterEvents[i + 1];

                if (NextEvent) {
                    const {
                        league_game_event_type: nextEventType,
                        player_id: nextEventPlayerID,
                        league_game_event_player_number: nextEventPlayerNumber
                    } = NextEvent;

                    if (nextEventType === "AST") {
                        PointsEvent.eventAssistingPlayerID = nextEventPlayerID;
                        PointsEvent.eventAssistingPlayerNumber = nextEventPlayerNumber;
                        // @ts-ignore
                        const eventAssistingPlayerAssistNumber = GameEventsSummary.players[eventPlayerID]?.AST || 0;
                        PointsEvent.eventAssistingPlayerAssistNumber = eventAssistingPlayerAssistNumber + 1;
                        eventOrderNr++;
                        PointsEvent.eventOrderNr += `, ${eventOrderNr}`
                    }

                }

                //@ts-ignore
                GameProgress[quarterNumber].push(PointsEvent);

            }

            else if (["1PTA", "2PTA", "3PTA"].includes(eventType)) {

                let eventThrowType = 0;

                if (eventType === "1PTA") {
                    eventThrowType = 1;
                } else if (eventType === "2PTA") {
                    eventThrowType = 2;
                } else if (eventType === "3PTA") {
                    eventThrowType = 3;
                }

                if (!eventThrowType) {
                    continue;
                }

                if (eventDescription?.text === "zablokowany") {
                    continue;
                }

                // @ts-ignore
                const eventThrowingPlayerThrowsNumber = GameEventsSummary.players[eventPlayerID][eventType] || 0;
                // @ts-ignore
                const eventThrowingPlayerAccurateThrowsNumber = GameEventsSummary.players[eventPlayerID][eventType.replace("A", "")] || 0;

                const ThrowEvent: GameProgressThrowEventInterface = {
                    eventType: GameProgressEventTypes.THROW, eventThrowType, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription,
                    eventThrowingPlayerID: eventPlayerID, eventThrowingPlayerTeamID: eventTeamID,
                    eventThrowingPlayerNumber: eventPlayerNumber,
                    eventThrowingPlayerThrowsNumber: eventThrowingPlayerThrowsNumber + eventThrowingPlayerAccurateThrowsNumber,
                    eventThrowingPlayerAccurateThrowsNumber: eventThrowingPlayerAccurateThrowsNumber,
                }

                //@ts-ignore
                GameProgress[quarterNumber].push(ThrowEvent);

            }


            else if (["PLAYER_O_REB", "PLAYER_D_REB"].includes(eventType)) {

                let reboundType;

                if (eventType === "PLAYER_O_REB") {
                    reboundType = "O";
                } else if (eventType === "PLAYER_D_REB") {
                    reboundType = "D";
                }

                if (!reboundType) {
                    continue;
                }

                // @ts-ignore
                const eventPlayerOffensiveReboundsNumber = GameEventsSummary.players[eventPlayerID].PLAYER_O_REB || 0;
                // @ts-ignore
                const eventPlayerDefensiveReboundsNumber = GameEventsSummary.players[eventPlayerID].PLAYER_D_REB || 0;


                const ReboundEvent: GameProgressReboundEventInterface = {
                    eventType: GameProgressEventTypes.REBOUND, eventReboundType: reboundType, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription,
                    eventReboundPlayerID: eventPlayerID, eventReboundPlayerTeamID: eventTeamID,
                    eventReboundPlayerNumber: eventPlayerNumber,
                    eventReboundPlayerOffensiveRebounds: eventPlayerOffensiveReboundsNumber,
                    eventReboundPlayerDefensiveRebounds: eventPlayerDefensiveReboundsNumber
                }

                //@ts-ignore
                GameProgress[quarterNumber].push(ReboundEvent);

            }


            else if (eventType === "BLK") {

                if (!["BLK VIC", "2PTA", "3PTA"].includes(previousEventType)) {
                    continue;
                }

                if (previousEventType === "2PTA" || previousEventType === "3PTA") {
                    const { league_game_event_description: previousEventDescription } = PreviousEvent;
                    if (previousEventDescription?.text !== "zablokowany") {
                        continue;
                    }
                }

                // @ts-ignore
                const eventBlockingPlayerBlocksNumber = GameEventsSummary.players[eventPlayerID]?.BLK;
                // @ts-ignore
                const eventBlockedPlayerBlockedNumber = GameEventsSummary.players[previousEventPlayerID]["BLK VIC"];

                // @ts-ignore
                const eventBlockedPlayerThrowsNumber = GameEventsSummary?.players[previousEventPlayerID][previousEventType] || 0;
                // @ts-ignore
                const eventBlockedPlayerAccurateThrowsNumber = GameEventsSummary?.players[previousEventPlayerID][previousEventType.replace("A", "")] || 0;

                const BlockEvent: GameProgressBlockEventInterface = {
                    eventType: GameProgressEventTypes.BLOCK, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription,
                    eventBlockingPlayerTeamID: eventTeamID, eventBlockedPlayerTeamID: previousEventTeamID,
                    eventBlockingPlayerID: eventPlayerID, eventBlockedPlayerID: previousEventPlayerID,
                    eventBlockingPlayerNumber: eventPlayerNumber, eventBlockedPlayerNumber: previousEventPlayerNumber,
                    eventBlockingPlayerBlocksNumber: eventBlockingPlayerBlocksNumber,
                    eventBlockedPlayerBlockedNumber: eventBlockedPlayerBlockedNumber,
                    // @ts-ignore
                    eventBlockedPlayerThrowType: previousEventType,
                    eventBlockedPlayerThrowsNumber, eventBlockedPlayerAccurateThrowsNumber,
                }

                //@ts-ignore
                GameProgress[quarterNumber].push(BlockEvent);

             }


            else if (eventType === "SUB_IN") {

                if (previousEventType !== "SUB_OUT") {
                    continue;
                }

                if (!eventTeamID) {
                    continue;
                }

                let eventOrderNumber = `${eventOrderNr}`;
                eventOrderNr++;
                eventOrderNumber += `, ${eventOrderNr}`;

                const SubstitutionEvent: GameProgressSubstitutionEventInterface = {
                    eventType: GameProgressEventTypes.SUBSTITUTION, eventOrderNr: `${eventOrderNumber}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription,
                    eventTeamID,
                    eventPlayerInID: eventPlayerID, eventPlayerInNumber: eventPlayerNumber,
                    eventPlayerOutID: previousEventPlayerID, eventPlayerOutNumber: previousEventPlayerNumber,
                };

                //@ts-ignore
                GameProgress[quarterNumber].push(SubstitutionEvent);

            }

            else if (eventType === "FOULED") {

                if (!["FOUL", "FOUL_TECH", "FOUL_DSQ", "FOUL_NS"].includes(previousEventType)) {
                    continue;
                }

                const {
                    league_game_event_description: foulEventDescription
                } = PreviousEvent;

                let foulType: "N" | "NS" | "T" | "D" = "N";

                if (previousEventType === "FOUL_TECH") {
                    foulType = "T";
                } else if (previousEventType === "FOUL_DSQ") {
                    foulType = "D";
                } else if (previousEventType === "FOUL_NS") {
                    foulType = "NS";
                }


                // @ts-ignore
                const eventFouledPlayerFouledNumber = GameEventsSummary.players[eventPlayerID]?.FOULED;
                // @ts-ignore
                const eventFoulingPlayerFoulsNumber = GameEventsSummary.players[previousEventPlayerID]?.FOUL;

                // @ts-ignore
                const eventFoulingPlayerTeamQuarterFouls = GameEventsSummary.teams[previousEventTeamID].quarters[quarterNumber].fouls;

                const FoulEvent: GameProgressFoulEventInterface = {
                    eventType: GameProgressEventTypes.FOUL, eventFoulType: foulType, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription: foulEventDescription,
                    eventFouledPlayerTeamID: eventTeamID, eventFoulingPlayerTeamID: previousEventTeamID,
                    eventFouledPlayerID: eventPlayerID, eventFoulingPlayerID: previousEventPlayerID,
                    eventFouledPlayerNumber: eventPlayerNumber, eventFoulingPlayerNumber: previousEventPlayerNumber,
                    eventFouledPlayerFouledNumber: eventFouledPlayerFouledNumber,
                    eventFoulingPlayerFoulsNumber: eventFoulingPlayerFoulsNumber,
                    eventFoulingPlayerTeamQuarterFoulsNumber: eventFoulingPlayerTeamQuarterFouls,
                }

                //@ts-ignore
                GameProgress[quarterNumber].push(FoulEvent);

            }

            else if (eventType === "TEAM_O_REB" || eventType === "TEAM_D_REB") {

                const TeamReboundEvent: GameProgressTeamReboundEventInterface = {
                    eventType: GameProgressEventTypes.TEAM_REBOUND, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber,
                    eventReboundType: eventType === "TEAM_O_REB" ? "O" : "D",
                    eventReboundTeamID: eventTeamID,
                    eventReboundTeamDefensiveRebounds: 0, eventReboundTeamOffensiveRebounds: 0,
                };

                //@ts-ignore
                GameProgress[quarterNumber].push(TeamReboundEvent);

            }

            else if (eventType === "TO") {

                // @ts-ignore
                const eventTurnoverPlayerTurnoversNumber = GameEventsSummary.players[eventPlayerID]?.TO;

                const TurnoverEvent: GameProgressTurnoverEventInterface = {
                    eventType: GameProgressEventTypes.TURNOVER, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime,  eventQuarter: +quarterNumber, eventDescription,
                    eventTurnoverPlayerTeamID: eventTeamID, eventTurnoverPlayerPlayerID: eventPlayerID, eventTurnoverPlayerPlayerNumber: eventPlayerNumber, eventTurnoverPlayerTurnoversNumber,
                }

                //@ts-ignore
                GameProgress[quarterNumber].push(TurnoverEvent);

                const NextEvent = QuarterEvents[i + 1];

                if (NextEvent) {

                    const { league_game_event_type: nextEventType } = NextEvent;

                    if (nextEventType === "STL") {

                        const {
                            team_id: nextEventTeamID,
                            player_id: nextEventPlayerID, league_game_event_player_number: nextEventPlayerNumber
                        } = NextEvent;

                        // @ts-ignore
                        const eventStealingPlayerStealsNumber = (GameEventsSummary.players[eventPlayerID]?.STL || 0) + 1;

                        TurnoverEvent.evenStealingPlayerTeamID = nextEventTeamID;
                        TurnoverEvent.eventStealingPlayerPlayerID = nextEventPlayerID;
                        TurnoverEvent.eventStealingPlayerPlayerNumber = nextEventPlayerNumber;
                        TurnoverEvent.eventStealingPlayerStealsNumber = eventStealingPlayerStealsNumber;

                        eventOrderNr++;
                        TurnoverEvent.eventOrderNr += `, ${eventOrderNr}`;

                    }

                }

            }

            else {

                const DefaultEvent: GameProgressDefaultEventInterface = {
                    eventType: GameProgressEventTypes.DEFAULT, eventSubType: eventType, eventOrderNr: `${eventOrderNr}`,
                    eventID, eventTime, eventQuarter: +quarterNumber, eventDescription,
                    eventTeamID: eventTeamID, eventPlayerID, eventPlayerNumber, eventRepeatsNumber: eventPlayerEventRepeatNumber,
                }

                //@ts-ignore
                GameProgress[quarterNumber].push(DefaultEvent);

            }

            if (eventType === "Q_START" || eventType === "Q_END") {
                continue;
            }

            eventOrderNr++;

        }

    }

    return GameProgress;

};


export default _createGameProgressDetails;
